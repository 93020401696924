import React from "react";
import { loaderSize } from "../constants";
import { useGetFormContext } from "../Providers/FormProvider";
import LoadingDiv from "./LoadingDiv";
import "./FormSubmit.scss";

type SubmitProps = {
    className?:string;
    initialMessage: string;
}

export function FormSubmit(props:SubmitProps) {
    const {response, submitForm} = useGetFormContext();
    const arrowLineClass = "arrow_line";
    const checkLineClass = "check_line";
    const result = response.results;

    const onClick = async () => {
        submitForm();
    };
    const handleKeyPress = async (e:React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key==="Enter"){
            onClick();
        }
    };
    const extraClass = props.className?props.className:"";
    const classes = "text_white form_container send_message_container main_text " + extraClass;

    if (result && result.success) {
        return (
            <div className={classes}>
                <div style={{display:"flex"}}>
                    <div>
                        Submitted!
                    </div>
                    <div className="check_mark">
                        <div className={checkLineClass + " check_side"}></div>
                        <div className={checkLineClass + " check_bottom"}></div>
                    </div>
                </div>
            </div>
        );
    } else if (!response.isSubmitting && !response.isRedirecting) {
        return (
            <div className={classes}>
                <div
                    className="form_button"
                    tabIndex={0}
                    onKeyPress={handleKeyPress}
                    onClick={onClick}
                >
                    <div
                        className="send_message_text noselect"
                    >
                        {props.initialMessage}
                    </div>
                    <div
                        className="arrow"
                    >
                        <div className={arrowLineClass + " arrow_top"}></div>
                        <div className={arrowLineClass + " arrow_middle"}></div>
                        <div className={arrowLineClass + " arrow_bottom"}></div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <LoadingDiv
                loaderSize={loaderSize.small}
            />
        );
    }
}
